import React, { useState, useMemo } from 'react'
import { Link } from 'gatsby'
import cx from 'classnames'
import imageEp02 from '../../../images/bg-quiz-ep-02.jpg'
import imageOralTrtPlus from '../../../images/bg-quiz-oral-trt-plus.jpg'
import imageOralTrt from '../../../images/bg-quiz-oral-trt.jpg'
import { validateEmail } from '../../../utils/format'
import { states, statesAvailable, statesNotServe } from './states'
import { isValidForEp02, isValidForOralTrtPlus, isValidForOralTrt, getRecommendation } from './functions'
import styles from './style.module.sass'

const STEP_2 = [
  { value: 'boost', text: 'Boost your body\'s natural testosterone production' },
  { value: 'exogenous', text: 'Add exogenous (external bio-identical) testosterone as a supplement' },
  { value: 'combination', text: 'Combination treatment' }
]

const STEP_3 = [
  { value: 'yes', text: 'Preserve fertility' }, { value: 'no', text: 'Fertility not important' }
]

export default () => {
  const [selectActive1, setSelectActive1] = useState(false)
  const [selectActive2, setSelectActive2] = useState(false)
  const [selectActive3, setSelectActive3] = useState(false)
  const [question1, setQuestion1] = useState(undefined)
  const [question2, setQuestion2] = useState(undefined)
  const [question3, setQuestion3] = useState(undefined)
  const [submitted, setSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)

  const selectHandler = (e, func, value) => {
    e.stopPropagation()
    selectsClose()
    func(!value)
  }

  const selectsClose = () => {
    setSelectActive1(false)
    setSelectActive2(false)
    setSelectActive3(false)
  }

  const selectOptionHandler = (e, func, selected, value) => {
    e.stopPropagation()

    if (selected === value) {
      func(undefined)
    } else {
      func(value)
    }
  }

  const validForEp02 = useMemo(() => {
    return isValidForEp02(question1, question2, question3)
  }, [question1, question2, question3])

  const validForOralTrtPlus = useMemo(() => {
    return isValidForOralTrtPlus(question1, question2, question3)
  }, [question1, question2, question3])

  const validForOralTrt = useMemo(() => {
    return isValidForOralTrt(question1, question2, question3)
  }, [question1, question2, question3])

  const formHandler = (evt) => {
    evt.preventDefault()
    const { email } = evt.target

    if (!email.value || loading || !validateEmail(email.value)) {
      return
    }

    setLoading(true)

    fetch('https://app.maximustribe.com/community/access', {
      method: 'POST',
      body: JSON.stringify({ email: email.value, href: window.location.href }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        if (response.ok) {
          return response.json()
        }
        return Promise.reject(response)
      })
      .then(data => {
        setTimeout(() => {
          setSubmit(true)
          setLoading(false)
        }, 600)
      })
      .catch(error => console.warn(error))
  }

  return (
    <div className={styles.quiz} onClick={selectsClose}>
      <div className={styles.wrap}>
        <h1 className={styles.title}>First, let’s find your<br/>custom recommendation.</h1>
        <p className={styles.info}>Select Filters:</p>

        <div className={styles.selects}>
          <div className={cx(styles.select, {
            [styles.selectActive]: selectActive1
          })} onClick={(e) => selectHandler(e, setSelectActive1, selectActive1)}>
            <p className={styles.selectTitle}>{!selectActive1 && question1 ? question1 : 'Select State'} <span /></p>
            <ul className={styles.selectList}>
              {states.map((item, index) => (
                <li 
                  className={cx(styles.selectItem, {
                    [styles.selectItemActive]: item === question1
                  })}
                  key={index}
                  onClick={(e) => selectOptionHandler(e, setQuestion1, question1, item)}
                >{item}</li>
              ))}
            </ul>
          </div>

          <div className={cx(styles.select, {
            [styles.selectActive]: selectActive2
          })} onClick={(e) => selectHandler(e, setSelectActive2, selectActive2)}>
            <p className={styles.selectTitle}>{!selectActive2 && question2 ? STEP_2.find(i => i.value === question2).text : 'Select Testosterone Type'} <span /></p>
            <ul className={styles.selectList}>
              {STEP_2.map((item, index) => (
                <li 
                  className={cx(styles.selectItem, {
                    [styles.selectItemActive]: item.value === question2
                  })}
                  key={index}
                  onClick={(e) => selectOptionHandler(e, setQuestion2, question2, item.value)}
                >{item.text}</li>
              ))}
            </ul>
          </div>

          <div className={cx(styles.select, {
            [styles.selectActive]: selectActive3
          })} onClick={(e) => selectHandler(e, setSelectActive3, selectActive3)}>
            <p className={styles.selectTitle}>{!selectActive3 && question3 ? STEP_3.find(i => i.value === question3).text : 'Select Fertility Consideration'} <span /></p>
            <ul className={styles.selectList}>
              {STEP_3.map((item, index) => (
                <li 
                  className={cx(styles.selectItem, {
                    [styles.selectItemActive]: item.value === question3
                  })}
                  key={index}
                  onClick={(e) => selectOptionHandler(e, setQuestion3, question3, item.value)}
                >{item.text}</li>
              ))}
            </ul>
          </div>                    
        </div>

        <div className={styles.finish}>
          {validForEp02 || validForOralTrtPlus || validForOralTrt ? (
            <>
              <h2 className={styles.finishTitle}>Your Recommended Product(s)</h2>
              <p className={styles.finishDesc}>{getRecommendation(validForEp02, validForOralTrtPlus, validForOralTrt, question1, question2, question3)}</p>

              <div className={cx(styles.products, {
                [styles.productsEp02]: validForEp02,
                [styles.productsOralTrtPlus]: validForOralTrtPlus,
                [styles.productsOralTrt]: validForOralTrt
              })}>
                <div className={styles.desktop}>
                  <div className={styles.row}>
                    <div className={styles.product}>
                      <img src={imageEp02} alt="EP-02" className={styles.productImage}/>
                    </div>
                    <div className={styles.product}>
                      <img src={imageOralTrtPlus} alt="Oral TRT+" className={styles.productImage}/>
                    </div>
                    <div className={styles.product}>
                      <img src={imageOralTrt} alt="Oral TRT" className={styles.productImage}/>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.product}>
                      <p className={styles.productName}>EP-02</p>
                    </div>
                    <div className={styles.product}>
                      <p className={styles.productName}>Oral TRT+</p>
                    </div>
                    <div className={styles.product}>
                      <p className={styles.productName}>Oral TRT</p>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.product}>
                      <p className={styles.productInfo}>The best place to start with testosterone optimization</p>
                    </div>
                    <div className={styles.product}>
                      <p className={styles.productInfo}>The powerhouse of testosterone replacement therapy</p>
                    </div>
                    <div className={styles.product}>
                      <p className={styles.productInfo}>The best alternative to traditional testosterone replacement therapy</p>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.product}>
                      <ul className={styles.productBenefits}>
                        <li>Enclomiphene</li>
                        <li>Pregnenolone</li>
                      </ul>
                    </div>
                    <div className={styles.product}>
                      <ul className={styles.productBenefits}>
                        <li>Native Testosterone</li>
                        <li>Enclomiphene</li>
                        <li>Pregnenolone</li>
                      </ul>
                    </div>
                    <div className={styles.product}>
                      <ul className={styles.productBenefits}>
                        <li>Native Testosterone</li>
                      </ul>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.product}>
                      <p className={styles.productText}>Improve testosterone levels without the use of exogenous testosterone. EP-02 is an easy and reliable way to boost your own testicular function. Pregnenolone elevates mood and increases energy levels.</p>
                    </div>
                    <div className={styles.product}>
                      <p className={styles.productText}>Significantly increase your testosterone levels by combining oral testosterone and enclomiphene. Enclomiphene allows for the maintenance of fertility markers and testicular function, making this the perfect option for those seeking maximal testosterone levels.</p>
                    </div>
                    <div className={styles.product}>
                      <p className={styles.productText}>Native testosterone is enveloped in a lipid matrix to allow for lymphatic absorption, bypassing the liver’s first-pass metabolism. Oral TRT sets the gold standard for a convenient, effective, and liver-safe way to take TRT.</p>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.product}>
                      <Link to={'/testosterone/enclomiphene'} className={styles.productButton}>Learn More</Link>
                    </div>
                    <div className={styles.product}>
                      <Link to={'/testosterone/testosterone-replacement-therapy'} className={styles.productButton}>Learn More</Link>
                    </div>
                    <div className={styles.product}>
                      <Link to={'/testosterone/oral-trt'} className={styles.productButton}>Learn More</Link>
                    </div>
                  </div>                    
                </div>
                <div className={styles.mobile}>
                  <div className={styles.product}>
                    <img src={imageEp02} alt="EP-02" className={styles.productImage}/>
                    <p className={styles.productName}>EP-02</p>
                    <p className={styles.productInfo}>The best place to start with testosterone optimization</p>
                    <ul className={styles.productBenefits}>
                      <li>Enclomiphene</li>
                      <li>Pregnenolone</li>
                    </ul>
                    <p className={styles.productText}>Improve testosterone levels without the use of exogenous testosterone. EP-02 is an easy and reliable way to boost your own testicular function. Pregnenolone elevates mood and increases energy levels.</p>
                    <Link to={'/testosterone/enclomiphene'} className={styles.productButton}>Learn More</Link>
                  </div>
                  <div className={styles.product}>
                    <img src={imageOralTrtPlus} alt="Oral TRT+" className={styles.productImage}/>
                    <p className={styles.productName}>Oral TRT+</p>
                    <p className={styles.productInfo}>The powerhouse of testosterone replacement therapy</p>
                    <ul className={styles.productBenefits}>
                      <li>Native Testosterone</li>
                      <li>Enclomiphene</li>
                      <li>Pregnenolone</li>
                    </ul>
                    <p className={styles.productText}>Significantly increase your testosterone levels by combining oral testosterone and enclomiphene. Enclomiphene allows for the maintenance of fertility markers and testicular function, making this the perfect option for those seeking maximal testosterone levels.</p>
                    <Link to={'/testosterone/testosterone-replacement-therapy'} className={styles.productButton}>Learn More</Link>
                  </div>
                  <div className={styles.product}>
                    <img src={imageOralTrt} alt="Oral TRT" className={styles.productImage}/>
                    <p className={styles.productName}>Oral TRT</p>
                    <p className={styles.productInfo}>The best alternative to traditional testosterone replacement therapy</p>
                    <ul className={styles.productBenefits}>
                      <li>Native Testosterone</li>
                    </ul>
                    <p className={styles.productText}>Native testosterone is enveloped in a lipid matrix to allow for lymphatic absorption, bypassing the liver’s first-pass metabolism. Oral TRT sets the gold standard for a convenient, effective, and liver-safe way to take TRT.</p>
                    <Link to={'/testosterone/oral-trt'} className={styles.productButton}>Learn More</Link>
                  </div>                    
                </div>
              </div>
            </>
          ) : (
            <>
              <h2 className={styles.finishTitle}>Unfortunately Maximus is not yet available in your area.</h2>
              <p className={styles.finishDesc}> Please enter your email address if you'd like to be informed when we expand to serve additional states</p>
              {submitted ? (
                <p className={styles.submitted}>Thanks! We'll be in touch</p>
              ) : (
                <form 
                  className={cx(styles.form, {
                    [styles.formLoading]: loading,
                  })} 
                  onSubmit={formHandler}
                >
                  <input type="emails" name="email" className={styles.formEmail} placeholder="Enter your email"/>
                  <input type="submit" value="Submit" className={styles.formSubmit}/>
                </form>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}